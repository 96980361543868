@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
body{
    background: #ffffff;
    background-color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
}
.body-container{max-width: 100%;overflow: hidden;}
*{
    font-size: 18px;
    color: #161616;
    font-family: 'Source Sans Pro', sans-serif;
}
h1,h2,h3,h4,h6{
    font-family: 'PT Serif', serif;
}
p{
    text-align: justify;
    line-height: 1.6;
}
a{text-decoration: none;}
.pt-35{
    padding-top: 35px;
}
.pt-45{padding-top: 45px;}
.pl-35{padding-left: 35px;}
.w-30{width: 30px;}
.w-75{width: 75%;height: 115px;}
.bl-1{
    border-left: 1px solid #ddd;
}
.h-400{height: 400px;}
.sonacas-menu-bg{background-image: url("./images/sonacas-header.webp");background-size: cover;}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-142px);
    }

    100% {
        transform: translateY(0px);
    }
}

.sonacas-menu .navbar-brand, #offcanvasNavbar-expand-xl{z-index: 999999;margin-left: 5%;}
.sonacas-menu .navbar-brand{padding-bottom: 10px;}
.form-control:focus {
    box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.sonacas-menu .navbar-brand:before {
    position: absolute;
    content: '';
    top: 0;
    left: -45px;
    bottom: 0;
    width: 30%;
    background: #ffffff;
    transform: skewX(16deg);
    transform-origin: 50% 100%;
    will-change: transform;
    pointer-events: none;
    z-index: -1;

}
.sonacas-menu .navbar-brand:after{
    position: absolute;
        content: '';
        top: 0;
        right: -45px;
        bottom: 0;
        width: 75%;
        background: #ffffffab;
        transform: skewX(16deg);
        transform-origin: 50% 100%;
        will-change: transform;
        pointer-events: none;
        z-index: 0;

}
.sonacas-menu .navbar-brand img{width: 175px;display: block;padding-bottom: 5px;}
.sonacas-menu .navbar-brand span.logo-caption{font-size: 11px;
    padding-left: 27px;
    text-align: center;
    position: absolute;
    bottom: 1px;
    padding-top: 3px;}
.navbar-expand-xl .navbar-nav .nav-link {
    padding: 10px 15px !important;
    font-weight: 400;
    border-radius: 25px;
    color: #000;
    transition: 0.5s;
}
/* .dropdown-toggle::after {
    content: "\2965";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: inherit;
    border: 0px;
} */
.sonacas-menu .nav-item.dropdown .dropdown-menu a:first-child:before {
    border-radius: 3px 3px 0 0;
}
#notfound {
    position: relative;
    height: 100vh;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 710px;
    width: 100%;
    padding-left: 190px;
    line-height: 1.4;
}
.notfound .notfound-404 {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
}
.notfound .notfound-404 h1 {
    font-family: passion one, cursive;
    color: #00b5c3;
    font-size: 150px;
    letter-spacing: 15.5px;
    margin: 0;
    font-weight: 900;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound h2 {
    font-family: raleway, sans-serif;
    color: #292929;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: 0;
}
.notfound p {
    font-family: raleway, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}
.notfound a {
    font-family: raleway, sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #fff;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #292929;
    font-weight: 700;
    -webkit-box-shadow: 0 4px 15px -5px rgb(0 0 0 / 30%);
    box-shadow: 0 4px 15px -5px rgb(0 0 0 / 30%);
    -webkit-transition: .2s all;
    transition: .2s all;
}
.homeslider .carousel-control-prev-icon, .homeslider .carousel-control-next-icon, .homeslider .carousel-indicators{display: none;}
.sonacas-menu .nav-item.dropdown .dropdown-menu a:before {
    content: '';
    display: block;
    height: 100%;
    width: 3px;
    background-color: #d6dfeb;
    left: -5px;
    top: 0;
    position: absolute;
    -webkit-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
}
.sonacas-menu .nav-item.dropdown .dropdown-menu a:hover:before {
    background-color: #b01f24;
}
.sonacas-menu .nav-item.dropdown .dropdown-menu a {
    perspective: 1000px;
    opacity: 0;
    transform-origin: top center;
}

.sonacas-menu .nav-item.dropdown .dropdown-menu a:nth-child(n) {
    animation-name: menu2;
    animation-duration: 300ms;
    animation-delay: -170ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    padding-left: 15px;
}

.sonacas-menu .nav-item.dropdown .dropdown-menu a:nth-child(2n) {
    animation-name: menu2;
    animation-duration: 300ms;
    animation-delay: -40ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.sonacas-menu .nav-item.dropdown .dropdown-menu a:nth-child(3n) {
    animation-name: menu2;
    animation-duration: 300ms;
    animation-delay: 90ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.sonacas-menu .nav-item.dropdown .dropdown-menu a:nth-child(4n) {
    animation-name: menu2;
    animation-duration: 300ms;
    animation-delay: 220ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.sonacas-menu .nav-item.dropdown .dropdown-menu a:nth-child(5n) {
    animation-name: menu2;
    animation-duration: 300ms;
    animation-delay: 350ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}



@keyframes menu2 {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}
.dropdown-menu {
    transform: scaleY(1);
    transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    background: #e9efef;
    color: #fff;
    border: 0;
    border-radius: 0;
}
.dropdown-menu.show {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    background: #e9efef;
    color: #fff;
    border: 0;
    border-radius: 0;
}
.dropdown-menu.show .dropdown-item, .dropdown-menu .dropdown-item{
    color: #023047;
}
.dropdown-menu.show .dropdown-item:hover, .dropdown-menu .dropdown-item:hover {
    background: transparent;
    color: #b01f24;
}
.navbar-expand-xl .navbar-nav .nav-link:hover, .navbar-expand-xl .navbar-nav .nav-link.active{
    /* background: #023047; */
    color: #b21f24;
    /* transform: scale(1.1); */
    transition: 0.5s;
    /* border-radius: 25px; */
    
}

#offcanvasNavbar-expand-xl {
    margin-left: 0;
}
.navbar-expand-xl .navbar-nav .nav-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background: #16151b;
    top: 0;
    animation: out 0.2s cubic-bezier(1, 0, 0.58, 0.97) 1 both;
}

.navbar-expand-xl .navbar-nav .nav-link:hover:before {
    animation: in 0.2s cubic-bezier(1, 0, 0.58, 0.97) 1 both;
}

@keyframes in {
    0% {
        width: 0;
        left: 0;
        right: auto;
    }

    100% {
        left: auto;
        right: auto;
        width: 60%;
    }
}

@keyframes out {
    0% {
        width: 60%;
        left: auto;
        right: 0;
    }

    100% {
        width: 0;
        left: auto;
        right: 0;
    }
}

@keyframes show {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar-expand-xl .navbar-nav .nav-link:nth-child(1) {
    animation: show 0.2s 0.1s ease 1 both;
}

.navbar-expand-xl .navbar-nav .nav-link:nth-child(2) {
    animation: show 0.2s 0.2s ease 1 both;
}

.navbar-expand-xl .navbar-nav .nav-link:nth-child(3) {
    animation: show 0.2s 0.3s ease 1 both;
}

.navbar-expand-xl .navbar-nav .nav-link:nth-child(4) {
    animation: show 0.2s 0.4s ease 1 both;
}

.navbar-expand-xl .navbar-nav .nav-link:nth-child(5) {
    animation: show 0.2s 0.5s ease 1 both;
}
.navbar-expand-xl .navbar-nav .nav-link:nth-child(6) {
    animation: show 0.2s 0.6s ease 1 both;
}
.navbar-expand-xl .navbar-nav .nav-link:nth-child(7) {
    animation: show 0.2s 0.7s ease 1 both;
}
.navbar-expand-xl .navbar-nav .nav-link:nth-child(8) {
    animation: show 0.2s 0.8s ease 1 both;
}
.navbar-expand-xl .navbar-nav .nav-link:nth-child(9) {
    animation: show 0.2s 0.9s ease 1 both;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    top: 90%;
}


.sonacas-menu .nav-item.dropdown .dropdown-menu {
    
    transform: scaleY(1);
    transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    background-color: #e9efef;
    color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    -webkit-box-shadow: 0px 3px 0px 0px rgb(176 31 36 / 53%);
    -moz-box-shadow: 0px 3px 0px 0px rgb(176 31 36 / 53%);
    box-shadow: 0px 2px 0px 0px rgb(176 31 36 / 53%);
}
.sonacas-menu .navbar-toggler {
    border: 0px;
}

.sonacas-menu .navbar-toggler:focus {
    box-shadow: unset;
}

.menu-icon {
    height: 30px;
    width: 30px;
    position: fixed;
    z-index: 2;
    right: 25px;
    cursor: pointer;
    z-index: 999999;
}

.menu-icon__line {
    height: 2px;
    width: 30px;
    display: block;
    background-color: #b01f24;
    margin-bottom: 4px;
    transition: transform 0.2s ease, background-color 0.5s ease;
}

.menu-icon__line-left {
    width: 15px;
}

.menu-icon__line-right {
    width: 15px;
    float: right;
}
.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
    max-width: 100%;
}

.cl-effect-11 a::before {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 10px 0;
    max-width: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    content: attr(data-hover);
    -webkit-transition: max-width 0.5s;
    -moz-transition: max-width 0.5s;
    transition: max-width 0.5s;
}
/* @media screen and (min-width: 1024px){ button.icon-scroll-top {
    background-image: url("./images/arrow-top_white.svg");
}
} */
button.icon-scroll-top {
    background-position: 0 50%;
    background-repeat: no-repeat;
    bottom: 122px;
    color: #212121;
    font-size: 14px;
    height: 34px;
    letter-spacing: -.24px;
    line-height: 34px;
    opacity: 0;
    padding: 0 20px 0 30px;
    position: fixed;
    right: 63px;
    transform: translateZ(0);
    transform: rotate(-90deg);
    transition: all .2s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    visibility: visible;
    z-index: 20;
}

.bg-helpline-box{
    background: #ffb703c4;
        position: relative;
        z-index: 9;
}
.hlepline-content{
    margin: 8px;
    /* color: #fff; */
    color: #b21f24;
    font-weight: 600;
}
.hlepline-content a{
    color: #023047;
    text-decoration: none;
    font-size: 16px;
}
.hlepline-content a:hover{
    color: #b21f24;
}
.bell-box{text-align: end;
    vertical-align: middle;
    margin: 8px 0px;}
.sonacarousel .carousel-indicators{
    display: none;
}
.bg-banner1 img,
.bg-banner2 img,
.bg-banner3 img {
    height: 100%;
}

.sonacarousel .carousel-control-next{
    left: 0;
    top: 100px;
    bottom: initial;
    opacity: 0.9;
}
.sonacarousel .carousel-control-prev{
    top: initial;
    bottom: initial;
    opacity: 0.9;
}
.sonacarousel .carousel-control-prev{margin-bottom: 55px;}
.sonacarousel .carousel-control-next .carousel-control-next-icon, .sonacarousel .carousel-control-prev .carousel-control-prev-icon{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    background-image: unset;
    border-radius: 100%;
    border: 1px solid #fff;
    padding: 22px;
    width: unset;
    height: unset;
}
.sonacarousel .carousel-control-next .carousel-control-next-icon{
    top: 90px;
    position: absolute;}
.sonacarousel .carousel-control-next .carousel-control-next-icon:before {
    content: "\f061";
}
.sonacarousel .carousel-control-prev .carousel-control-prev-icon{
    position: absolute;
    bottom: 250px;
}
.sonacarousel .carousel-control-prev .carousel-control-prev-icon:before{content: "\f060";}
.highlight-news{
    padding: 0px;
    color: #023047;
    font-size: 18px;
    font-weight: 600;
}
.highlight-news .marquee{
    margin: 8px 0px;
    font-weight: 400;
}
.highlight-news .marquee a{
    color: #94151a;
    z-index: 99999;
    position: relative;
    font-size: 14px;
}
.highlight-news .marquee a:hover{
    color: #d40e15;
}
/* .btn-applyon{
    background: #161616;
    padding: 5px 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff!important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    vertical-align: middle;
} */

.btn-applyon {
    align-items: center;
    background-color: #b01f24;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff!important;
    cursor: pointer;
    display: inline-flex;
    font-weight: 600;
    justify-content: center;
    line-height: 19px;
    max-width: 480px;
    min-height: 30px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.btn-applyon:hover,
.btn-applyon:focus {
    background-color: #16437E;
    color: #ffffff;
}

.btn-applyon:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}

.btn-applyon:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}
.hlepline-content a .apply-icon{
        position: relative;
        top: 1px;
        color: #fff;
}
.apply-adm-box{
    position: relative;
/*    left: 190px;*/
    border-right: 5px solid #ff9a03;
    padding-right: 10px;
    margin-top: -35px;
    z-index: 6;
}
.btn-apply{
    padding: 15px 30px;
    /* background: #023047;
    color: #ffffff; */
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 20px;
    background: #b12525;
/*        color: #ffffff;*/
color: #ffcb05;
}
.btn-apply:hover{
        /* background: #ff9a03; */
        background: #681616;
    border: initial;
    color: #fff;
}
.btn-apply:hover:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}
btn
/* title */

.head-title h1:before {
    margin: 0 15px 10px 0;
}
.head-title h1:after {
    margin: 0 0 10px 15px;
}
.head-title h1:before,
.head-title h1:after {
    content: "";
    width: 50px;
    height: 2px;
    background: #b01f24;
    display: inline-block;
}
.title-head h3 {
    position: relative;
    
}
.title-head h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #b01f24;
    left: 0;
    bottom: 0;
}
/* Why join sona  */
.bg-why-sona{
    padding: 80px 0px;
    background-image: url('./images/whysona-bg.webp');
    background-size: cover;
    background-position: left;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    
}
.h3-s2 {
    position: absolute;
    bottom: 0px;
    left: 16%;
    height: 204px;
    width: 204px;
    border: 30px solid #ffc400;
    border-radius: 50%;
    z-index: 2;
}
.head-title h1 {
    color: #023047;
}
.h-font{
    font-size: 2.5rem;
}
.text-color {
    color: #ffb703;
}
.why-sona-box{
    padding: 40px;
    background: #ffffff;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 7px 5px; */
    box-shadow: rgb(100 100 111 / 20%) 1px 1px 1px 1px;
    border-radius: 10px;
    height: 100%;
}
.why-sona-box .nav-tabs .nav-link{
    border: 0px;
    color: #023047;
    font-size: 18px;
    font-weight: 600;
    position: relative;
}
.why-sona-box .nav-tabs{
    border: 0px;
}
.why-sona-box .nav-tabs .nav-link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 8px;
    left: 50%;
    position: absolute;
    background: #ffb703;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    border-radius: 20px;
}

.why-sona-box .nav-tabs .nav-link:hover:after {
    width: 40%;
    left: 29%;
}
.why-sona-box .nav-tabs .nav-link.active{
    color: #ffb703;
}
.why-sona-box .nav-tabs .nav-link.active:after{
    width: 40%;
    left: 29%;
}
.btn-view-more{
    background-color: #023047;
    color: #fff;
}
.why-sona-box .img-box{
    text-align: center;
}
.why-sona-box .img-box img{
    border: 0px;
    border-radius: 10px;
}
.why-sona-box .content-box{
    padding: 30px 0px;
}
.why-sona-box .tab-content{
    padding: 25px;
}
.why-sona-box .content-box h2{
    margin-bottom: 20px;
}
.admission-box{
    text-align: center;
    background: #fff;
    /* padding: 45px; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 7px 0px; */
    box-shadow: rgb(100 100 111 / 20%) 1px 1px 1px 1px;
    border-radius: 10px;
}
/* Enrolled Form */

.bg-enroll{
    background: #8ecae6;
    padding: 80px 0px;
/*    background-image: url("./images/section-bg03.png");*/
    /* background-image: url('./images/parallax.png'); */
    /*background-size: contain;
    background-position: left;
    background-repeat: no-repeat;*/
}
.enrolled-box{
    background: #023047;
    padding: 50px;
    border-radius: 5px;
}
.enrolled-box .title-box h1{
    color: #fff;
    text-align: center;
}
#enrolled-form{
    padding: 35px 0px;
}
#enrolled-form .btn-submit{
    color: #fff;
    background: #ffb703;
    padding: 5px 40px;
    border-radius: 25px;
    font-size: 22px;
    letter-spacing: 4px;
}
#enrolled-form .btn-submit:hover{
    background-color: #fff;
    color: #ffb703;
    border: 1px solid #ffb703;
}
#enrolled-form .form-group{
    padding: 10px 0px;
}
/* News & Events  */

.bg-newsevnt{
    background: #fff;
    /* background-image: url("./images/1.png"); */
    /* padding: 80px 0px;
    background-image: url("./images/bg-news-events.png");
    background-size: contain;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat; */
}
.bg-newsevnt .news-box, .bg-eventsform .news-box,
.bg-newsevnt .events-box{
    padding: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background: #fff;
}
.bg-newsevnt .news-box .carousel-indicators{
        bottom: -40px;
}
.bg-newsevnt .news-box .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    background-color: #023047;
    border-radius: 50%;
}
.newsdate-box{
    position: relative;
    text-align: end;
    top: -45px;
}
.news-date{
    color: #8c8c8c;
    font-weight: 600;
}
#enrolled-form .form-control{
padding: 10px;
border-radius: 25px;
background: #023047;
color: #6c756c;
}
#enrolled-form .form-control option {color: #6c756c;}
.news-single-box .card-body{
    padding: 0px 20px;
}
.news-single-box .card-body img{
    border-radius: 10px;
}
.news-single-box .card-title{
    margin-top: 10px;
}
.news-single-box .card-title a{
    text-decoration: none;
    color: #023047;
}
.news-box .carousel-control-prev, .news-box .carousel-control-next{
    display: none;
}
/* .adm-body{
    padding: 8px 0px;
        height: 400px;
        overflow: y;
} */
.adm-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    width: 8px;
}

.adm-body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.adm-body::-webkit-scrollbar-thumb {
    background-color: #6a88ff;
    width: 8px;
}
.announcement-box .icon-box{text-align: center;vertical-align: middle;margin: auto;}
.announcement-box .icon-box img{border:4px solid #eee;border-radius: 50%;padding: 2px;}
.announcement-box .announcement-content {text-align: start;}
.announcement-box .announcement-content a{color: #023047;font-weight: 400;}
.announcement-box{padding: 5px;
    margin-bottom: 10px;}
/* Up Coming events */
.search-box{
    padding: 15px 20px;
    border-color: #daf3ff;
}
.events-box .search-box .search-input{padding: 10px;background-color: #daf3ff;border: 1px solid #daf3ff}
.upcoming-evnts-box .news-item{
    margin-bottom: 10px;
    padding: 15px 20px;
    line-height: 1;
    display: inline-flex;
    width: 100%;
    cursor: pointer;
}
.upcoming-evnts-box .news-item .list-dot{
    padding: 8px 25px 8px 0px;
}
.upcoming-evnts-box .news-item:hover{
    background-color: #023047;
    color: #fff;
}
.upcoming-evnts-box{
  padding: 8px 0px;
  height: 400px;
  overflow: auto;
}
.upcoming-evnts-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    width: 8px;
}

.upcoming-evnts-box::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.upcoming-evnts-box::-webkit-scrollbar-thumb {
    background-color: #6a88ff;
    width: 8px;
}
.upcoming-evnts-box .news-item a h4{
    color: #023047;
}
.upcoming-evnts-box .news-item .news-date path{
    color: #878889;
}
.news-date path {
    color: #878889;
}
.upcoming-evnts-box .news-item .list-dot path{
    color: #ffa561;
}
.upcoming-evnts-box .news-item:hover > .news-decp a h4 {
    color: #fff;
}
.news-decp{padding: 5px;}
/* Campus Life */
.bg-campus{
    /* background: linear-gradient(to bottom, #8ecae6 70%, #fff 30%); */
    /* padding: 80px 0px; */
    padding: 0px;
    
}
.bg-camp{
    /* background-image: url("./images/campuslife1.png");
    background-size: contain; */
    background-repeat: no-repeat;
}
.btn-readmore{color: #fff;
    text-align: end;
    background: #023047;}
.bg-campus-img img{
    width: 100%;
    height: 100%;
}


.bgcampimg {
    width: 120px;
    position: absolute;
    margin-top: -115px;
}

/*  */
section.home-section-3 {
    /* background: linear-gradient(to bottom, #8ecae6 70%, #fff 30%); */
    background: linear-gradient(to bottom, #023047 70%, #fff 30%);
    
    /* padding: 80px 0px; */
    /* padding: 10px 0 10px bg-campus */
   
    
}

.bgcamp{
    width: 100%;
    height: 100%;
    /* background-image: url("./images/bg-campus.png"); */
    padding: 80px 0px;
    background-size: cover;
    background-position: bottom;
}
.news__date{color: #161616;}
.news__date .fa-calendar{
    font-size: 14px;
}
.experience {
    padding: 30px 58px 0;
    position: relative;
    background: #023047bd;
}

.experience h2 {
    /* font-family: Merriweather, serif; */
    font-size: 35px;
    font-weight: 400;
    margin: 0 0 10px;
    color: #fff
}
.experience h2 span.text-color{font-family: 'PT Serif', serif;}
.experience p {
    font-size: 18px;
    font-weight: 600;
    color: #fff
}

.experience h3 {
    font-size: 60px;
    font-weight: 700;
    /* font-family: Montserrat, sans-serif; */
    color: #fff
}

.experience h5 {
    font-size: 18px;
    /* font-family: Montserrat, sans-serif; */
    color: #fff
}

.experience:before {
    content: "";
    background: url("./images/frame.svg");
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    top: 0
}

.img-panel {
    position: relative;
    overflow: hidden;
}

.img-panel img {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    width: 100%
}

.img-panel:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.facilities-box {
    position: relative
}
.facilities-box .img-panel{border: 10px solid #fff;}
.facilities-box .img-panel img{
    border-radius: unset;
}
.facilities-box p a {
    position: absolute;
    bottom: 20px;
    color: #fff;
    left: 30px;
    font-size: 22px;
    z-index: 999;
    font-weight: 600;
}

.col-md-4.pl-1.pr-1 {
    padding: 1px !important
}

.facilities-box .img-panel:before {
    content: "";
    background: linear-gradient(0deg, rgb(0 0 0 / 75%) 0, rgb(0 0 0 / 0) 52%);
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    top: 0
}

.facilities-box p a:after {
    width: 9px;
    content: "";
    height: 9px;
    border-left: 1px solid #fff !important;
    border-bottom: 1px solid #fff !important;
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
    position: absolute;
    top: 15px;
    right: -20px;
    display: inline-block;
    text-indent: 0;
    padding: 2px;
    text-align: right
}
.camp-list li  {
    /* font-family: Merriweather, serif; */
    font-weight: 500;
    color: #fff;
}
.camp-list li i{
    color: #b21f24;
}
/*  */
/* our programme */
.ourprogrammes {
    padding-bottom: 80px;
    background: linear-gradient(to bottom, #ffffff 50%, #8ecae6 50%);
}

/* .ourprogrammes .head-title h1{color: #fff;} */


.bg-camp .head-title h1 {
    color: #fff;
}
.adm-img{background-image: url("./images/enquirybg.webp");padding: 40px;position: relative;border-radius: 10px;}
.adm-img:after{
    background-image: url("./images/white-brush-mask.svg");
    content: "";
    height: 50px;
    width: 100%;
    position: absolute;
    background-size: cover;
    z-index: 2;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: bottom;
}
.adm-img .adm-header h2{color: #fff;font-size: 1.5rem;}
.adm-descp{padding: 20px 45px 5px;}
.adm-descp .enqform .form-group{
    margin-bottom: 1px;
}
form label.error {
    color: #db2929;
    font-size: 14px;
}
.adm-descp .enqform .form-group .form-control{
    border-radius: 0px;
    padding: 5px;
    font-size: 16px;
}
.adm-footer{padding: 5px 0px 25px;}
.adm-footer .btn-view-more{border-radius: 0px;}
video {
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
}

.bg-video-wrap .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .7) 50%);
    background-size: 3px 3px;
    z-index: 2;
}
/* our instituion */
/* .bg-institution{
    background: linear-gradient(to bottom, #ffffff 10%, #8ecae6 90%);
    padding-top: 80px;
    padding-bottom: 20px;
} */
.bg-ourgroup{
    background-image: url("./images/banyan-tree.webp");
    background-size: cover;
    background-position: center;
    background-color: #0279af;
    background-attachment: fixed;
}
.align-group{padding: 45px;position: relative;
    z-index: 9;}
.ourgroup-histry p{color: #ffffff;text-align: justify;}
.bg-ourgroup-data{position: relative;backdrop-filter: grayscale(1);}
.bg-ourgroup-data:before {
    z-index: 2;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0279af;
    background-image: -webkit-gradient(linear, left top, right top, from(#0279af), color-stop(#0279af), color-stop(#0279af), to(#0279af));
    /* background-image: linear-gradient(to right, #b10f57, #D10946, #FF286B, #AE005E); */
    background-image: linear-gradient(to right, #0279af, #0279af, #0279af, #0279af);
    opacity: 0.8;
}
.head-title-group h1{font-size: 50px;color: #ffffff;z-index: 9;}
.our-institute-warapper .col-lg-6{margin-bottom: 10px;}
.ourgroupbox{padding: 5px 0px 0px;
    /* height: 100%; */
    text-align: center;
    background: #f4f5f6;
    margin-top: -25px;
    left: 15px;
    z-index: 9;
    position: relative;
    margin-bottom: 25px;}
.our-group-item .visit-us{padding: 5px;}
.our-group-item .visit-us img{display: unset;}
.our-institute-warapper{position: relative;z-index: 9;}
.our-group-item figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 100%;
    max-width: 100%;
    max-height: 250px;
    width: 100%;
    height: 250px;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}
.our-group-item figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}
.our-group-item figure figcaption,
.our-group-item figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.our-group-item figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.our-group-item figure.effect-sadie figcaption::before,
.our-group-item figure.effect-sadie p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.our-group-item figure.effect-sadie figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
    background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
    content: '';
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
}

.our-group-item figure figcaption::before,
.our-group-item figure figcaption::after {
    pointer-events: none;
}
.our-group-item figure.effect-sadie h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: #fff;
    -webkit-transition: -webkit-transform 0.35s, color 0.35s;
    transition: transform 0.35s, color 0.35s;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

.our-group-item figure h2,
.our-group-item figure p {
    margin: 0;
}

.our-group-item figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}
.our-group-item figure.effect-sadie p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    text-align: center;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}
.head-title-group {
    padding: 60px 90px;
}
.our-group-item figure.effect-sadie p a{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    text-align: center;
}
.our-group-item figure.effect-sadie figcaption::before,
.our-group-item figure.effect-sadie p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}
.our-group-item figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.our-group-item figure figcaption,
.our-group-item figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.our-group-item figure.effect-sadie figcaption::before,
.our-group-item figure.effect-sadie p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.our-group-item figure.effect-sadie:hover figcaption::before,
.our-group-item figure.effect-sadie:hover a {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.our-institute-warapper .content-descp{
    position: absolute;
    bottom: 10px;
    text-align: end;
    right: 10px;}
.our-institute-warapper .readmore{
    text-decoration: none;
    color: #023047;
    text-transform: uppercase;
}
.our-institute-warapper .readmore:hover{color: #ffb703;}
.our-institute-warapper .card{
    margin: 0px 15px;
    border-radius: 15px;
    border: unset;
    height: 100%;
}
.our-institute-warapper .card .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.our-institute-warapper .slick-dots{display: none!important;}
.our-news-events .slick-dots {
    display: block !important;
    bottom: -15px;
}
.our-news-events .slick-dots li button:before{
    color: #fff;
}
.our-news-events .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #ffb703!important;
}
.our-institute-warapper .slick-arrow.slick-next:before,
.our-institute-warapper .slick-arrow.slick-prev:before{
font-size: 36px;  
}
.our-institute-warapper .slick-track, .our-institute-warapper .slick-initialized .slick-slide .col-lg-3, .our-institute-warapper .slick-initialized .slick-slide div:first-child ,.our-institute-warapper .slick-list{height: 100%;}
.our-institute-warapper .card-title{height: auto!important;padding-bottom: 5px;}

/* Footer */
.bg-footer{
    padding: 60px 0px 0px;
    /* background-color: #023047; #f2f2f2 */
    background-color: #ededed;
    color: #ffffff;
    position: relative;
    /* margin-top: 80px; */
   
}
.bg-student{
    /* background-image: url("./images/footerbgs.webp"); */
        background-size: cover;
        background-position: bottom;
}
.copy-rights{padding-top: 25px;}
.bg-footer h4,.bg-footer p,.bg-footer a{
    /* color: #ffffff; */
    color: #161616;
    line-height: 1.5;}
.contact-address .contact-details p a i{color: #db2929;}
.bg-footer a:hover{
    /* color: #ffb703; */
    color: #b01f24;
}
.bg-footer a path{color: #ff9a03;}
.bg-footer a svg{font-size: 20px;margin-right: 6px;}
.bg-footer .footer-sublinks .list-group a {font-size: 16px;}
.bg-footer .footer-sublinks .social-mediaIcons{margin-top: 20px;}
.bg-footer .footer-sublinks .social-mediaIcons a i{color: #ffffff;margin: 5px;}
.bg-footer .footer-sublinks .social-mediaIcons a.social-linedin i{color: #fff;}
.bg-footer .footer-sublinks .social-mediaIcons a {font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 28px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    /* box-shadow: 0 0 0 1px rgb(255 255 255 / 40%); */
    margin: 0 2px;
    color: #fff;
    opacity: 0.75;
    border-style: inset;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-facebook i.fa{
    
    color: #3b5998;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-instagram i.fa{
    /* color: #fb3958;  */
    color: #fb3958;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-twitter i.fa{
    /* color: #3cf;  */
    color: #3cf;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-youtube i.fa{
    /* color: #ff0000;  */
    color: #ff0000;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-linedin i.fa{
    /* color: #ffffff;  */
    color: #3b5998;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-facebook:hover{
    border-style: outset;
    transition: all 0.5s ease;
    opacity: 0.9;
    border-color: #3b5998;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-instagram:hover{
    /* color: #fb3958;  */
    border-color: #fb3958;
    transition: all 0.5s ease;
    border-style: outset;
    opacity: 0.9;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-twitter:hover{
    /* color: #3cf;  */
    border-color: #3cf;
    transition: all 0.5s ease;
    border-style: outset;
    opacity: 0.9;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-youtube:hover{
    /* color: #ff0000;  */
    border-color: #ff0000;
    transition: all 0.5s ease;
    border-style: outset;
    opacity: 0.9;
}
.bg-footer .footer-sublinks .social-mediaIcons a.social-linedin:hover{
    /* color: #ffffff;  */
    border-color: #3b5998;
   transition: all 0.5s ease;
   border-style: outset;
   opacity: 0.9;
}

.copyright-section{
    /* background: #00364ee0; */
    background: #bbbbbce0;
    padding-bottom: 10px;    margin-top: 5px;
}
.stick-right {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 2.5em;
    margin: auto;
    background: #b01f24;
    color: #fff;
    z-index: 9999;
}
.importantpoint span {
    padding: 10px;
    margin: 5px;
    background: #ddd;
}
.importantpoint li i{
    color: #ffcb05;
}
.stick-right b{color: #fff;}
.stick-right {
    right: 0;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: rotate(90deg) translate(50%, 50%);
    -moz-transform: rotate(90deg) translate(50%, 50%);
    -ms-transform: rotate(90deg) translate(50%, 50%);
    -o-transform: rotate(90deg) translate(50%, 50%);
    transform: rotate(90deg) translate(50%, 50%);
}
/* different techniques for iPad screening */
@media only screen and (max-width: 1500px) {

    /* styles for browsers larger than 1440px; */
    .navbar-expand-xl .navbar-nav .nav-link {
        padding: 10px 8px !important;
        
        border-radius: 25px;
    }
}
@media only screen and (max-width: 1200px) and (min-width:990px) {

    /* styles for browsers larger than 1440px; */
    .why-sona-box .nav-tabs .nav-link {
        padding: 6px!important;
    }
}
@media only screen and (max-width: 1300px) {

    /* styles for browsers larger than 1440px; */
    .navbar-expand-xl .navbar-nav .nav-link {
        padding: 10px 6px !important;
        
        border-radius: 25px;
    }
}

@media only screen and (max-width: 990px) {
    .bgnews .aside_img_title {
            bottom: 10px;
        }
        .hero__text {
    top: -50px;
    position: relative;
}
.hero__text h2{
    font-size: 22px;
        line-height: 40px;
}
.hero__text .primary-btn {
    top: -35px;
    font-size: 12px;
}
   .bgnews .aside_img-column-linedec {
   right: 10px;
    height: 80px;
    width: 100px;
    top: 10px;
}
    .why-sona-box {
        padding: 20px 0px;
        margin-bottom: 20px;
    }
    .why-sona-box .content-box{
        padding-bottom: 0px;
        margin-top: 20px;
    }
     .bg-newsevnt .news-box,
    .bg-newsevnt .events-box{
        padding: 10px;
    }
    .upcoming-evnts-box{
        padding: 10px;
    }
        .campus-slide .carousel-control-prev,
        .campus-slide .carousel-control-next{
            position: relative;
            
        }
}
@media only screen and (min-width: 990px) and (max-width:1300px) {
    .why-sona-box .content-box {
        padding: 10px;
    }
    .why-sona-box {
            padding: 20px;
    }
     .upcoming-evnts-box .news-item {
            margin-bottom: 20px;
            padding: 8px;
            line-height: 1;
            display: inline-flex;
            width: 100%;
    }
    .upcoming-evnts-box{
        padding: 8px;
    }
    .bg-newsevnt .news-box, .bg-newsevnt .events-box{
        padding: 20px;
    }

} 
@media only screen and (max-width: 770px) {
    .hero__text {
            top: -50px;
            position: relative;
        }
    
        .hero__text h2 {
            font-size: 18px;
            line-height: 20px;
        }
    
        .hero__text .primary-btn {
            top: -35px;
            font-size: 12px;
            padding: 14px 15px 12px;
        }
        .hero__text span {
    font-size: 12px;
}
    .upcoming-evnts-box .news-item .list-dot {
            padding: 8px 8px 8px 0px;
        }
    .facilities-box p a:after{right: -10px!important;top: 10px;}
    .our-institute-warapper .card-title {
           padding-bottom: 5px;
        }
    .enrolled-box {
        padding: 25px;
    }
    .bg-enroll {
            padding: 20px;
    }
    .bg-why-sona{
        padding: 20px;
    }
    .admission-box{
        margin-top: 20px;
    }
    .bgcamp{padding:30px 20px 20px!important;}
    .facilities-box p a{
        left:15px!important;
        bottom:10px!important;
        text-align: justify;
        font-size: 16px!important;
    }
    .ourprogrammes{padding: 20px;}
    .why-sona-box .tab-content {
        padding: 10px;
    }
    .btn-apply{
        font-size: 16px;
        padding: 10px 20px;
    }
    .apply-adm-box{
        margin-top: -8px;
        left: 10px;
    }
    /* .bg-newsevnt{
        padding: 20px;
    } */
    .bg-campus{
        padding: 90px 20px 20px;
    }
    .align-group{
        padding: 20px;
    }
    .head-title-group {
    padding: 40px 40px 20px;
}
    .upcoming-evnts-box .news-item a h4{font-size: 18px;}
    .upcoming-evnts-box .news-item{
        padding: 10px;
    }
    .news-date i{font-size: 14px;}
    .news-single-box .card-body{padding: 0px 10px;}
    .bg-footer{padding: 20px;}
    .our-institute-warapper .card{margin: 0px 10px;}
    .sonacarousel .carousel-control-prev .carousel-control-prev-icon{
        bottom: 130px;
    }
    .sonacarousel .carousel-control-next .carousel-control-next-icon {
    top: 10px;
}
.sonacarousel .carousel-control-next .carousel-control-next-icon,
.sonacarousel .carousel-control-prev .carousel-control-prev-icon{
    padding: 10px;
}
.our-institute-warapper .slick-initialized .slick-prev {
    left: -20px;
}
.our-institute-warapper .slick-initialized .slick-next {
    right: -3px;
}
.experience {
    padding: 20px 20px 0;
    position: relative;
}
}

@media only screen and (max-width: 500px) {
    .navbar-brand img{ width: 180px;min-width: 125px;}
    .navbar-brand span{ font-size: 12px;}
    .hero__text h2 {
    font-size: 16px;
    line-height: 12px;
}
.sonacas-menu{
    z-index: 888;
}
.modal-content{z-index: 99999;}
.sonacas-menu .navbar-brand:before {
    width: 75%!important;
}
.hero__text span{margin-bottom: 2px;}
.carousel-indicators{display: none;}
.hero__text .primary-btn {
    top: -27px;
    font-size: 11px;
    padding: 10px 12px 12px;
}
.bg-footer {
    padding: 0px;
}
.bg-student .container{padding: 20px;}
.facilities-box p a {
    left: 15px !important;
    bottom: 20px !important;
}

.adm-descp {
    padding: 20px 20px 5px;
}
}
.footer-sec-title h4{position: relative;margin-bottom: 15px;}
.footer-sec-title h4:before {
    width: 15px;
}

.footer-sec-title h4:before,
.footer-sec-title h4:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    border-radius: 20px;
    background: linear-gradient(to right, #ffcb05 0, #5f3bff 51%, #7355f7 100%);
    background-size: 200%;
}
.footer-sec-title h4:after {
    width: 45px;
    left: 20px;
}
.footer-sublinks ul li:before {
    /* content: '📌'; */
    content: '\27BD';
    color: #db2929;
    float: left;
    font-size:15px;
    margin: 3px 5px 0px 0px;
}


@media screen and (max-width: 990px){ 
    .sonacas-menu .navbar-brand:before {
    width: 60%;
    
}
.sonacas-menu .navbar-brand:after {
   width: 51%;
    
}
    .program-accordion {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    min-width: auto;
    height: 100%;
    overflow: auto;
    width: 100%;
}
.program-tabs {
    width: 100%;
    height: auto;
}
.program-tabs:hover {
    width: 100%;
}
}
.our-recruiters{
    background: #f4f5f6;
    padding: 45px 0px;
}
.our-recrute-slide .card{border-radius: 0px;margin: 15px;border: 0px;background: unset;
    border: 1px solid #ddd;}
 

   @media (max-width: 991px) {
       .autocross-new .img-box {
           padding: 15px;
       }

       .autocross-box {
           padding: 15px;
       }

       .nasir-activities2 {
           padding-top: 30px;
       }

       .wel-box h4 {
           font: 400 1.1em "Montserrat", Arial, sans-serif !important;
       }
   }

   .nasir-activities2.style2 {
       padding-bottom: 0;
       background: #eeeff1;
   }

   @media (max-width: 991px) {
       .nasir-activities2 .sec-header {
           padding-top: 0;
       }
   }

   .nasir-activities2 .sec-header+p {
       margin-bottom: 55px;
       color: #898989;
       font-size: 15px;
       line-height: 26px;
       letter-spacing: 0.3px;
   }

   .nasir-activities2 .nav {
       padding: 0px;
       width: 100%;
   }

   .nasir-activities2 .nav li {
       position: relative;
       overflow: hidden;
       background: #000;
       cursor: pointer;
   }

   .nasir-activities2 .nav li a,
   .nasir-activities2 .nav li {
       color: #fff;
   }

   .nasir-activities2 .nav li a:hover {
       color: #f5f5f5;
   }

   .nasir-activities2 .nav li {
       width: calc(100% / 5);
       float: left;
       padding: 25px;
       background: #FFF;
   }

   @media (max-width: 991px) {
       .nasir-activities2 .nav li {
           width: calc(100% / 3);
           float: left;
       }
   }

   @media (max-width: 767px) {
       .nasir-activities2 .nav li {
           width: 50%;
       }
   }

   @media (max-width: 500px) {
       .nasir-activities2 .nav li {
           width: 384px;
           max-width: 100%;
           float: none;
           margin: 0 auto;
       }
           .hlepline-content a, .hlepline-content {
               font-size: 14px;
           }
    .hlepline-content.text-end{
    text-align: center!important;
}
   }

   .nasir-activities2 .nav li img {
       opacity: 1;
       transition: all 300ms linear 0s;
   }

   .nasir-activities2 .nav li:nth-child(2) .this-overlay {
       background: #0009;
   }

   .nasir-activities2 .nav li:nth-child(3) .this-overlay {
       background: #5322128c;
   }

   .nasir-activities2 .nav li:nth-child(4) .this-overlay {
       background: #2c3713b8;
   }

   .nasir-activities2 .nav li:nth-child(5) .this-overlay {
       background: #12274fb8;
   }

   .nasir-activities2 .nav li:hover img,
   .nasir-activities2 .nav li:focus img {
       transform: scale(1.1);
       opacity: .8;
   }

   .nasir-activities2 img {
       width: 100%;
       max-width: 100%;
       height: 100%;
   }

   .nasir-activities2 .this-overlay {
       position: absolute;
       top: 25px;
       left: 25px;
       right: 25px;
       bottom: 25px;
       opacity: 0.7;
       background: #0d1c209e;
       padding: 25px;
   }

   .nasir-activities2 .this-texts {
       position: absolute;
       display: table;
       left: 25px;
       right: 25px;
       bottom: 25px;
       padding: 25px;
       text-align: end;
   }

   .nasir-activities2 .this-ftitle {
       font-size: 22px;
       font-family: 'Source Serif Pro', serif;
       /* font-family: "Nova Square", cursive; */
       margin: 0;
       color: #fff;
       text-align: start;
       font-weight: 400;
   }

   .nasir-activities2 .this-stitle {
       margin: 0;
       /* font-family: "Nova Square", cursive; */
       font-weight: bold;
   }
   .year-start{
    color: #b21f24;
        position: relative;
        font-weight: 500;
        background: #fff;
        padding: 2px 8px;
        box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
        border-radius: 5px;
        top: 10px;
        right: 0px;
        text-align: end;
   }
   .our-recrute-title{height: 100%;vertical-align: middle;text-align: center;}
   .our-recrute-title h2{margin-top: 30px;}
   /* .contact-footer{border: 1px solid #005078;} */
   .contact-footer .contact-address{padding: 5px;}
@media only screen and (max-width: 1500px) and (min-width:1200px) {
   .nasir-activities2 .this-texts {
       position: absolute;
       display: table;
       left: 15px;
       right: 15px;
       bottom: 15px;
       padding: 25px;
       text-align: end;
   }
}
@media only screen and (max-width: 1300px) and (min-width:1200px) {
.nasir-activities2 .this-ftitle {
    font-size: 16px;
}
}
@media only screen and (max-width: 1200px) and (min-width:990px) {
.nasir-activities2 .this-ftitle {
    font-size: 16px;
    font-family: 'Source Serif Pro', serif;
    /* font-family: "Nova Square", cursive; */
    margin: 0;
    color: #fff;
    text-align: start;
    font-weight: 400;
}
.nasir-activities2 .this-texts {
    position: absolute;
    display: table;
    left: 15px;
    right: 15px;
    bottom: 15px;
    padding: 20px;
    text-align: end;
}
}

p.tamilfont.small {
    text-align: left;
}